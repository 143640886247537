import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clientSecret: String,
    returnUrl: String,
    publishableKey: String
  }

  connect() {
    const stripe = Stripe(this.publishableKeyValue)
    const elements = stripe.elements({
      clientSecret: this.clientSecretValue,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#2563eb',
          colorBackground: '#ffffff',
          colorText: '#1f2937',
          colorDanger: '#dc2626',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          spacingUnit: '4px',
          borderRadius: '8px',
        },
        rules: {
          '.Input': {
            border: '1px solid #e5e7eb',
            boxShadow: '0 1px 2px 0 rgba(0 0 0 0.05)',
            padding: '8px 12px'
          },
          '.Input:focus': {
            border: '1px solid #2563eb',
            boxShadow: '0 0 0 1px #2563eb'
          },
          '.Label': {
            color: '#4b5563',
            fontSize: '0.875rem',
            fontWeight: '500'
          },
          '.Error': {
            color: '#dc2626',
            fontSize: '0.875rem'
          }
        }
      }
    })

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment', {
      layout: {
        type: 'tabs',
        defaultCollapsed: false
      }
    })
    paymentElement.mount('#checkout-element')

    // Handle form submission
    const form = this.element
    form.addEventListener('submit', async (event) => {
      event.preventDefault()

      // Disable the submit button to prevent double submission
      const submitButton = form.querySelector('button[type="submit"]')
      submitButton.disabled = true
      submitButton.innerHTML = '<span class="inline-block animate-spin mr-2">↻</span> Processing...'

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.returnUrlValue
        }
      })

      if (error) {
        console.error('Payment failed:', error)
        // Re-enable the submit button
        submitButton.disabled = false
        submitButton.innerHTML = 'Complete Payment'

        // Show error to customer
        const errorDiv = document.createElement('div')
        errorDiv.className = 'mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm'
        errorDiv.textContent = error.message
        form.insertBefore(errorDiv, submitButton.parentElement)
      }
    })
  }
}